<template>
    <div id="app" class="hp-100">
        <router-view />
    </div>
</template>

<script>
// Add any global logic or state here if needed
export default {
    created() {
        const savedLanguage = localStorage.getItem('selectedLanguage') || 'KR'; // 기본 언어는 'KR'
        this.$setLang(savedLanguage); // 언어를 전환하는 메서드
    },
};
</script>

<style>
#app {
    /* display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh; */
}
</style>
