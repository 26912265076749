import { createApp, h } from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './plugin/i18n';

import '@/assets/css/style.css'
import VueSelect from 'vue-select'
import vSelect from 'vue-select'
import Vue3Signature from "vue3-signature"

// Set the components prop default to return our fresh components 
vSelect.props.components.default = () => ({
  OpenIndicator: {
    render: () => h('span', ``),
  },
});


// loadFonts()
// const cors = require('cors')
createApp(App)
  .use(router)
  .use(i18n)
  .use(Vue3Signature)
  .component("v-select", VueSelect)
  .mount('#app')
  
router.beforeEach((to,_,next) => {
    if (to.path === '/alarm'){
      next(false);
    } else {
      next()
    }
})