import { ref } from 'vue';
import translations from '../locales/translation.json';

export default {
    install(app) {
        // 언어 상태 관리
        const lang = ref(localStorage.getItem('selectedLanguage') || 'KR');

        // 번역 함수 설정
        app.config.globalProperties.$translate = (key, params = {}) => {
            const translation = translations.find(item => item["Unique Key"] === key);
            if (!translation) return key;  // 키가 없을 경우 기본 키 반환
            const text = translation[lang.value] || translation["EN"];

            // 중괄호 구문 ({count})을 매개변수로 대체
            return text.replace(/{(\w+)}/g, (_, match) => params[match] || '');
        };

        // 전역 프로퍼티로 언어 상태와 언어 변경 함수 추가
        app.config.globalProperties.$lang = lang;
        app.config.globalProperties.$setLang = (newLang) => {
            lang.value = newLang;
            localStorage.setItem('selectedLanguage', newLang); // 언어 설정을 localStorage에 저장
        };
    }
};
